import React, { useContext } from "react";

import { Switch, Route, Redirect } from "react-router-dom";
import { Welcome } from "./Welcome";
import { Signature } from "./Signature";
import { Login } from "./Auth";
import { AuthContext } from "../stores";

function PrivateRoute({
  children,
  ...rest
}: React.ComponentProps<typeof Route>) {
  const { user } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !!user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export const Screens = () => {
  return (
    <Switch>
      <Route exact path="/login">
        <Login />
      </Route>
      <PrivateRoute exact path="/">
        <Redirect to="/welcome" />
      </PrivateRoute>
      <PrivateRoute exact path="/welcome">
        <Welcome />
      </PrivateRoute>
      <PrivateRoute exact path="/signature">
        <Signature />
      </PrivateRoute>
    </Switch>
  );
};
