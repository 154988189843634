import "./App.css";

import React from "react";
import { BrowserRouter } from "react-router-dom";

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

import { Screens } from "./screens";
import {
  AuthContextProvider,
  HtmlContextProvider,
  PresetContextProvider,
  SignatureContextProvider,
  TemplateContextProvider,
} from "./stores";

const theme = createMuiTheme({
  typography: {
    body1: {
      fontSize: 14,
    },
    subtitle1: {
      fontSize: 14,
    },
  },
  palette: {
    primary: {
      main: "#009DE3",
    },
  },
});

function App() {
  return (
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <AuthContextProvider>
          <TemplateContextProvider>
            <PresetContextProvider>
              <SignatureContextProvider>
                <HtmlContextProvider>
                  <Screens />
                </HtmlContextProvider>
              </SignatureContextProvider>
            </PresetContextProvider>
          </TemplateContextProvider>
        </AuthContextProvider>
      </MuiThemeProvider>
    </BrowserRouter>
  );
}

export default App;
