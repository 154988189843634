import React, { createContext, useState } from "react";
import { noop } from "../util/noop";
import { User } from "../types";

interface Context {
  user?: User.User;
  login: (user: User.User) => void;
  loginAsGuest: () => void;
  logout: () => void;
}

export const AuthContext = createContext<Context>({
  user: undefined,
  login: noop,
  loginAsGuest: noop,
  logout: noop,
});

export function AuthContextProvider({
  children,
}: {
  children: JSX.Element;
}): React.ReactElement {
  const [user, setUser] = useState<User.User>();

  const login = (user: User.User) => setUser(user);
  const loginAsGuest = () => setUser(User.makeBySecret("guest"));
  const logout = () => setUser(undefined);

  return (
    <AuthContext.Provider
      value={{
        user: user,
        login: login,
        loginAsGuest: loginAsGuest,
        logout: logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
