import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

import { Button, Grid } from "@material-ui/core";

interface Props {
  html: string;
}

export const HtmlContent = ({ html }: Props) => {
  const [copied, setCopied] = useState(false);

  const fireCopiedNotification = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  return (
    <>
      <Grid item xs={12}>
        <CopyToClipboard text={html} onCopy={fireCopiedNotification}>
          <Button variant="contained" color="primary">
            Copia
          </Button>
        </CopyToClipboard>
        {copied && <span style={{ marginLeft: 20 }}>Copiato</span>}
      </Grid>
      <Grid item xs={12}>
        <div>
          <pre style={{ maxHeight: 500 }}>{html}</pre>
        </div>
      </Grid>
    </>
  );
};
