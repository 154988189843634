import React from "react";
import { Link } from "react-router-dom";

import { Button, Grid, Paper, Typography } from "@material-ui/core";

import { Footer } from "../../components/Common";
import { TemplatePicker } from "../../components/TemplatePicker";

export const Welcome = () => {
  return (
    <div className="container" style={{ marginTop: 20 }}>
      <div className="row">
        <div className="col-md-12" style={{ marginBottom: 20 }}>
          <Typography variant="h1">Modello firma</Typography>
        </div>
        <div className="col-md-12" style={{ marginBottom: 20 }}>
          <Paper style={{ padding: 16 }}>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h3">Iniziamo</Typography>
                <Typography>
                  Seleziona uno dei modelli disponibili dalla lista, oppure
                  inserisci l'url di un modello in formato{" "}
                  <code>.mustache</code>.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item sm></Grid>
                  <Grid item sm={6}>
                    <TemplatePicker />
                  </Grid>
                  <Grid item sm></Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justify="flex-end">
                  <Link to="/signature">
                    <Button variant="contained" color="primary">
                      Continua
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </div>
      <Footer />
    </div>
  );
};
