import React, { useContext } from "react";

import { Grid, Typography } from "@material-ui/core";

import { TemplateContext } from "../../stores";
import { TemplateListPicker } from "./TemplateListPicker";
import { TemplatePreview } from "./TemplatePreview";
import { TemplateUrlPicker } from "./TemplateUrlPicker";

export const TemplatePicker = () => {
  const { template, setTemplate } = useContext(TemplateContext);

  return (
    <>
      <Grid container spacing={5}>
        <Grid item md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Modello predefinito</Typography>
            </Grid>
            <Grid item xs={12}>
              <TemplateListPicker value={template} onChange={setTemplate} />
            </Grid>
            <Grid item xs={12}>
              <TemplatePreview value={template} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Modello personalizzato</Typography>
            </Grid>
            <Grid item xs={12}>
              <TemplateUrlPicker value={template} onChange={setTemplate} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
