import React, { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  CircularProgress,
  Typography,
  InputLabel,
  FormControl,
} from "@material-ui/core";

import { Template } from "../../types";

type Props = {
  value?: Template.Meta;
  onChange: (url: Template.Meta) => void;
};

export const TemplateListPicker = ({ value, onChange }: Props) => {
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const [templateList, setTemplateList] = useState<Template.Meta[]>([]);

  const fetchTemplateList = async () => {
    return fetch("/template-list.json")
      .then((response) => response.json())
      .then((templateList: Template.Meta[]) => setTemplateList(templateList));
  };

  useEffect(() => {
    setLoading(true);
    setError(false);
    fetchTemplateList()
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  }, []);

  const handleChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    const uri = e.target.value as string;
    onChange(
      templateList.find((x) => x.uri === uri) || Template.makeMetaFromUri(uri)
    );
  };

  if (isError)
    return (
      <Typography>
        Ops, qualcosa è andato storto durante il caricamento dei modelli.
      </Typography>
    );

  if (isLoading) return <CircularProgress />;

  return (
    <FormControl fullWidth>
      <InputLabel id="template-list-label">Modello</InputLabel>
      <Select
        labelId="template-list-label"
        id="template"
        value={value?.uri}
        onChange={handleChange}
        fullWidth
      >
        {templateList.map((x, i) => (
          <MenuItem value={x.uri} key={i}>
            {Template.toString(x)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
