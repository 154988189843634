import React, { createContext, useState, useEffect } from "react";
import { noop } from "../util/noop";
import { Template } from "../types";
import { getDefaultTemplate } from "../data/template";

interface Context {
  template?: Template.Template;
  setTemplate: (template: Template.Meta) => void;
}

export const TemplateContext = createContext<Context>({
  template: undefined,
  setTemplate: noop,
});

export function TemplateContextProvider({
  children,
}: {
  children: JSX.Element;
}): React.ReactElement {
  const [templateMeta, setTemplateMeta] = useState<Template.Meta>(
    getDefaultTemplate()
  );
  const [template, setTemplate] = useState<Template.Template | undefined>();

  useEffect(() => {
    if (!!templateMeta) {
      loadTemplate(templateMeta.uri)
        .then((content) =>
          setTemplate(
            Template.makeTemplate(templateMeta)(Template.makeContent(content))
          )
        )
        .catch((err) => Promise.reject(err));
    }
  }, [templateMeta]);

  return (
    <TemplateContext.Provider
      value={{
        template: template,
        setTemplate: setTemplateMeta,
      }}
    >
      {children}
    </TemplateContext.Provider>
  );
}

const loadTemplate = async (uri: string) => {
  return fetch(uri)
    .then((x) => new Promise((resolve) => setTimeout(() => resolve(x), 1000)))
    .then((response) => (response as Response).text());
};
