import React, { useContext } from "react";

import { Typography } from "@material-ui/core";

import { Footer } from "../../components/Common";
import { HtmlViewer } from "../../components/HtmlViewer/HtmlViewer";
import { PresetPicker } from "../../components/PresetPicker/PresetPicker";
import { SignaturePicker } from "../../components/SignaturePicker";
import { HtmlContext, PresetContext, SignatureContext } from "../../stores";
import { HtmlPreview } from "../../components/HtmlPreview";

export const Signature = () => {
  const styles = useStyles();

  const { html } = useContext(HtmlContext);
  const { current, presets, setPreset } = useContext(PresetContext);
  const { setSignature } = useContext(SignatureContext);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12" style={styles.box}>
          <Typography variant="h1">Generazione Firma</Typography>
        </div>
        <div className="col-md-12" style={styles.box}>
          <PresetPicker items={presets} onSelectedValue={setPreset} />
        </div>
        <div className="col-md-6" style={styles.box}>
          <SignaturePicker
            initialValues={current.signature}
            onSubmit={setSignature}
          />
        </div>
        <div className="col-md-6" style={styles.box}>
          <HtmlPreview html={html} />
        </div>
        <div className="col-md-6" style={styles.box}>
          <HtmlViewer html={html} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12" style={styles.box}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

const useStyles = () => ({
  box: {
    marginTop: 20,
  },
});
