import Mustache from "mustache";
import React, { createContext, useContext, useEffect, useState } from "react";

import { Html } from "../types";
import { SignatureContext } from "./signature";
import { TemplateContext } from "./template";

interface Context {
  html?: Html.Html;
}

export const HtmlContext = createContext<Context>({
  html: undefined,
});

export function HtmlContextProvider({
  children,
}: {
  children: JSX.Element;
}): React.ReactElement {
  const { template } = useContext(TemplateContext);
  const { signature } = useContext(SignatureContext);

  const [html, setHtml] = useState<Html.Html | undefined>();

  useEffect(() => {
    if (!!template && !!signature) {
      setHtml(Mustache.render(template.content, signature));
    }
  }, [template, signature]);

  return (
    <HtmlContext.Provider
      value={{
        html: html,
      }}
    >
      {children}
    </HtmlContext.Provider>
  );
}
