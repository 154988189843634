import React from "react";

import { Grid, Paper, Typography } from "@material-ui/core";

import { PreviewContent } from "./PreviewContent";
import { PreviewNotAvailable } from "./PreviewNotAvailable";

interface Props {
  html?: string;
}

export const HtmlPreview = ({ html }: Props) => {
  return (
    <Paper style={{ padding: 16 }}>
      <Grid container alignItems="flex-start" spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3">Preview</Typography>
        </Grid>
        <Grid item xs={12}>
          {html ? <PreviewContent html={html} /> : <PreviewNotAvailable />}
        </Grid>
      </Grid>
    </Paper>
  );
};
