import React, { createContext, useState, useContext, useEffect } from "react";
import { noop } from "../util/noop";
import { Preset } from "../types";
import { getDefaultPreset, getPresetsByUser } from "../data";
import { AuthContext } from "./auth";

interface Context {
  current: Preset.Preset;
  presets: Preset.Preset[];
  setPreset: (preset: Preset.Preset) => void;
}

export const PresetContext = createContext<Context>({
  current: getDefaultPreset(),
  presets: [],
  setPreset: noop,
});

export function PresetContextProvider({
  children,
}: {
  children: JSX.Element;
}): React.ReactElement {
  const { user } = useContext(AuthContext);

  const [presets, setPresets] = useState<Preset.Preset[]>([]);
  const [preset, setPreset] = useState<Preset.Preset>(getDefaultPreset());

  useEffect(() => {
    setPresets(getPresetsByUser(user?.presetGroup));
  }, [user]);

  return (
    <PresetContext.Provider
      value={{
        current: preset,
        presets: presets,
        setPreset: setPreset,
      }}
    >
      {children}
    </PresetContext.Provider>
  );
}
