import presets from "./Presets.json";

import { Signature, Preset } from "../types";

type SignatureT = Signature.Signature;
type PresetT = Preset.Preset;

const localStorageKey = "userDefinedPresets";

const chachePresets = () => {
  localStorage.setItem(localStorageKey, JSON.stringify(presets));
};

/** Return preset with id `id`. */
const getPresetById = (id: string): PresetT | undefined => {
  return getAllPresets().find((preset: PresetT) => preset.id === id);
};

const getPresetsByGroup = (group: string): PresetT[] => {
  return getAllPresets().filter((preset: PresetT) => preset.group === group);
};

/** Return an array of presets with group `group`. */
const getPresetsByUser = (user?: string): PresetT[] => {
  if (!user) return getPresetsByGroup("guest");

  if (user === "guest") return getPresetsByGroup("guest");

  return [...getPresetsByGroup("guest"), ...getPresetsByGroup(user)];
};

/** Return the default preset, i.e. the preset with `id=1`. */
const getDefaultPreset = (): PresetT => {
  const preset: PresetT | undefined = getPresetById("1");
  if (!preset) throw new Error("Missing default preset.");
  return preset;
};

/** Return an array of all presets. */
const getAllPresets = (): PresetT[] => {
  const userDefinedPresetsString: string | null = localStorage.getItem(
    localStorageKey
  );
  if (!userDefinedPresetsString) {
    chachePresets();
  }
  const presets: PresetT[] = JSON.parse(
    localStorage.getItem(localStorageKey) || "[]"
  );
  return presets.sort((a, b) => parseInt(a.id) - parseInt(b.id));
};

/** Add given preset to the store.  */
const addPreset = (values: SignatureT, name?: string): void => {
  const presets: PresetT[] = getAllPresets();
  const newId: string = (
    parseInt(
      presets.reduce((prev, curr) => {
        return { id: prev.id > curr.id ? prev.id : curr.id, ...curr };
      }).id
    ) + 1
  ).toString();
  addPresetWithDetails(newId, name || `PRESET ${newId}`, "guest", values);
};

const addPresetWithDetails = (
  id: string,
  name: string,
  group: string,
  signature: SignatureT
): void => {
  const presets: PresetT[] = getAllPresets();
  const newPreset: PresetT = {
    id: id,
    name: name,
    group: group,
    signature: signature,
  };

  localStorage.setItem(
    localStorageKey,
    JSON.stringify([...presets, newPreset])
  );
};

// Remove all user defined presets.
const resetPresets = (): void => {
  chachePresets();
};

export {
  getAllPresets,
  getPresetsByUser,
  getDefaultPreset,
  getPresetById,
  addPreset,
  resetPresets,
};
