import { setIn } from "final-form";
import { TextField } from "mui-rff";
import React from "react";
import { Form } from "react-final-form";
import * as Yup from "yup";

import {
  Button,
  FormControl,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";

import { Signature } from "../../types";

const validationSchema = Yup.object({
  firstName: Yup.string().required("Campo richiesto"),
  lastName: Yup.string(),
  role: Yup.string(),
  department: Yup.string(),
  site: Yup.string(),
  phone: Yup.string()
    .max(20, "Numero di telefono troppo lungo")
    .matches(new RegExp(/(^(\+?(\d+\s?)+))$/), "Numero di telefono non valido"),
  phoneInternal: Yup.string()
    .max(6)
    .matches(new RegExp(/^\d*$/), "Interno non valido"),
  email: Yup.string().email().required("Campo richiesto"),
  address: Yup.string(),

  legalName: Yup.string().required("Campo richiesto"),
  legalAddress: Yup.string(),
  legalMapsUrl: Yup.string().url(),
  legalPhone: Yup.string(),
  legalEmail: Yup.string().email(),
  websiteUrl: Yup.string().url(),
  logoUrl: Yup.string().url(),

  colorPrimary: Yup.string().matches(
    new RegExp(/#(\w{3}){1,2}/g),
    "Notazione HTML non valida"
  ),
  colorSecondary: Yup.string().matches(
    new RegExp(/#(\w{3}){1,2}/g),
    "Notazione HTML non valida"
  ),

  fbUrl: Yup.string().url(),
  twitterUrl: Yup.string().url(),
  linkedinUrl: Yup.string().url(),
  youtubeUrl: Yup.string().url(),

  fbIconUrl: Yup.string().url(),
  twitterIconUrl: Yup.string().url(),
  linkedinIconUrl: Yup.string().url(),
  youtubeIconUrl: Yup.string().url(),
  phoneIconUrl: Yup.string().url(),
  emailIconUrl: Yup.string().url(),
});

const validate = async (values: Signature.Signature) => {
  try {
    await validationSchema.validate(values, { abortEarly: false });
  } catch (err) {
    const errors = err.inner.reduce((formError: any, innerError: any) => {
      return setIn(formError, innerError.path, innerError.message);
    }, {});

    return errors;
  }
};

interface Props {
  initialValues: Signature.Signature;
  onSubmit: (signature: Signature.Signature) => void;
}

export const SignaturePicker = ({ initialValues, onSubmit }: Props) => {
  const handleSubmit = onSubmit;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={validate}
      render={({ handleSubmit, form, submitting }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Paper style={{ padding: 16 }}>
            <FormControl component="fieldset" fullWidth>
              <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h3" gutterBottom>
                    Firma
                  </Typography>
                  <Typography>
                    Compila il form con i tuoi dati. Nel riquadro a sinistra
                    otterrai il codice HTML generato per la firma. Copia ed
                    incolla il codice nel tuo programma di posta.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4">Utente</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5">Generatlità</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField name="firstName" label="Nome*" fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <TextField name="lastName" label="Cognome" fullWidth />
                </Grid>
                <Grid item xs={4}>
                  <TextField name="role" label="Ruolo" fullWidth />
                </Grid>
                <Grid item xs={4}>
                  <TextField name="department" label="Dipartimento" fullWidth />
                </Grid>
                <Grid item xs={4}>
                  <TextField name="site" label="Sede" fullWidth />
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="flex-start"
                spacing={2}
                style={{ marginTop: 20 }}
              >
                <Grid item xs={12}>
                  <Typography variant="h5">Recapiti</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    name="phone"
                    label="Telefono"
                    placeholder="+39 123 456 7890"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField name="phoneInternal" label="Interno" fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <TextField name="email" label="Email*" fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <TextField name="address" label="Indirizzo" fullWidth />
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="flex-start"
                spacing={2}
                style={{ marginTop: 20 }}
              >
                <Grid item xs={12}>
                  <Typography variant="h4">Azienda</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField name="legalName" label="Nome azienda*" fullWidth />
                </Grid>

                <Grid item xs={4}>
                  <TextField name="legalPhone" label="Telefono" fullWidth />
                </Grid>
                <Grid item xs={4}>
                  <TextField name="legalEmail" label="Email" fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <TextField name="legalAddress" label="Indirizzo" fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="legalMapsUrl"
                    label="Url indirizzo (maps)"
                    placeholder="https://goo.gl/maps/ABC123"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField name="websiteUrl" label="Sito web" fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <TextField name="logoUrl" label="Logo" fullWidth />
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="flex-start"
                spacing={2}
                style={{ marginTop: 20 }}
              >
                <Grid item xs={12}>
                  <Typography variant="h5">Social</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField name="fbUrl" label="Link Facebook" fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <TextField name="twitterUrl" label="Link Twitter" fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="linkedinUrl"
                    label="Link LinkedIn"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField name="youtubeUrl" label="Link YouTube" fullWidth />
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="flex-start"
                spacing={2}
                style={{ marginTop: 20 }}
              >
                <Grid item xs={12}>
                  <Typography variant="h4">Stile</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5">Colore</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="colorPrimary"
                    label="Colore Primario"
                    placeholder="#000 (notazione html)"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="colorSecondary"
                    label="Colore Secondario"
                    placeholder="#000 (notazione html)"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5">Icone</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="phoneIconUrl"
                    label="Url Icona Telefono"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="emailIconUrl"
                    label="Url Icona Email"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="fbIconUrl"
                    label="Url Icona Facebook"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="twitterIconUrl"
                    label="Url Icona Twitter"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="linkedinIconUrl"
                    label="Url Icona LinkedIn"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="youtubeIconUrl"
                    label="Url Icona YouTube"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="flex-start" spacing={2}>
                <Grid item style={{ marginTop: 16 }}>
                  <Button
                    type="button"
                    variant="contained"
                    onClick={() => form.reset(initialValues)}
                    disabled={submitting}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item style={{ marginTop: 16 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitting}
                  >
                    Genera
                  </Button>
                </Grid>
              </Grid>
            </FormControl>
          </Paper>
        </form>
      )}
    />
  );
};
