import React from "react";

import { Grid, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";

import { PresetGroup } from "./PresetGroup";

type Props = React.ComponentProps<typeof PresetGroup>;

export const PresetPicker = ({ items, onSelectedValue }: Props) => {
  return (
    <Paper style={{ padding: 16 }}>
      <Grid container alignItems="flex-start" spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3">Preset</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Scegli un modello di firma. La scelta del modello ti permetterà di
            risparmiare tempo nella creazione della firma, autocompilando alcuni
            campi.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="space-between">
            <Grid item xs={12}>
              <PresetGroup items={items} onSelectedValue={onSelectedValue} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
