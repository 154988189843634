import React, { createContext, useState, useEffect, useContext } from "react";
import { noop } from "../util/noop";
import { Signature } from "../types";
import { PresetContext } from "./preset";

interface Context {
  signature?: Signature.Signature;
  setSignature: (signature?: Signature.Signature) => void;
}

export const SignatureContext = createContext<Context>({
  signature: undefined,
  setSignature: noop,
});

export function SignatureContextProvider({
  children,
}: {
  children: JSX.Element;
}): React.ReactElement {
  const { current } = useContext(PresetContext);
  const [signature, setSignature] = useState<Signature.Signature | undefined>();

  useEffect(() => setSignature(current.signature), [current]);

  return (
    <SignatureContext.Provider
      value={{
        signature: signature,
        setSignature: setSignature,
      }}
    >
      {children}
    </SignatureContext.Provider>
  );
}
