import React from "react";

import { Button, ButtonGroup } from "@material-ui/core";

import { Preset } from "../../types";

interface Props {
  items: Preset.Preset[];
  onSelectedValue: (preset: Preset.Preset) => void;
}

export const PresetGroup = ({ items, onSelectedValue }: Props) => {
  return (
    <ButtonGroup color="primary" aria-label="Presets">
      {items.map((preset, i) => {
        return (
          <Button key={i} onClick={() => onSelectedValue(preset)}>
            {Preset.toString(preset)}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};
