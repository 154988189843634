import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="col-md-12">
        <div className="text-center" style={{ fontSize: 10, lineHeight: 1 }}>
          <p>
            <b>NEXIO INFORMATICA SRL Unipersonale</b>
          </p>
          <p>
            <b className="text-muted">P.IVA:</b> 04030320982
          </p>
          <p>
            <b className="text-muted">Sede legale:</b> Via G. Marconi, 50, Temù
            (BS) 25050, Italia
          </p>
          <p>
            <b className="text-muted">Sede operativa:</b> Via Nazionale, 19,
            Capo di Ponte (BS), 25044, Italia
          </p>
          <p>
            <b className="text-muted">Email:</b> info@nexioinformatica.com,{" "}
            <b className="text-muted">Tel.</b> +393935314690
          </p>
          <p>
            <b className="text-muted">Capitale sociale:</b> € 10.000,00 i.v.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
