export type Meta = {
  uri: string;
  name?: string;
  preview?: string | null;
};

export type Content = {
  content: string;
}

export type Template = Meta & Content;

export const makeMeta = (uri: string) => (
  opt: { name?: string; preview?: string | null } = {}
): Meta => ({ uri: uri, ...opt });

export const makeMetaFromUri = (uri: string): Meta => makeMeta(uri)();

export const toString = <T extends Meta>(template: T) =>
  template.name ? template.name : template.uri;

export const makeContent = (content: string) => ({content: content});

export const makeTemplate = (meta: Meta) => (content: Content): Template => ({...meta, ...content});
