import React from "react";

import { Grid, Paper, Typography } from "@material-ui/core";

import { HtmlContent } from "./HtmlContent";
import { HtmlNotAvailable } from "./HtmlNotAvailable";

interface Props {
  html?: string;
}

export const HtmlViewer = ({ html }: Props) => {
  return (
    <Paper style={{ padding: 16 }}>
      <Grid container alignItems="flex-start" spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3">Codice</Typography>
        </Grid>
        <Grid item xs={12}>
          {(html && <HtmlContent html={html} />) || <HtmlNotAvailable />}
        </Grid>
      </Grid>
    </Paper>
  );
};
