import React from "react";
import { Template } from "../../types";
import { Typography } from "@material-ui/core";

type Props = {
  value?: Template.Template;
};

export const TemplatePreview = ({ value }: Props) => {
  if (!value) return <Typography>Seleziona un template</Typography>;
  if (!value.preview) return <Typography>Nessuna preview</Typography>;
  return (
    <img
      src={value.preview}
      alt="preview"
      className="img-fluid img-responsive"
    />
  );
};
