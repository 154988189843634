import { setIn } from "final-form";
import { TextField } from "mui-rff";
import React, { useContext } from "react";
import { Form } from "react-final-form";
import { useHistory } from "react-router";
import * as Yup from "yup";

import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { Footer } from "../../components/Common";
import { AuthContext } from "../../stores";
import { User } from "../../types";

interface FormValues {
  secret: string;
}

const initialValues = {
  secret: "",
};

const validationSchema = Yup.object({
  secret: Yup.string().required("Il campo Codice Utente è richiesto"),
});

const validate = async (values: FormValues) => {
  try {
    await validationSchema.validate(values, { abortEarly: false });
  } catch (err) {
    const errors = err.inner.reduce((formError: any, innerError: any) => {
      return setIn(formError, innerError.path, innerError.message);
    }, {});

    return errors;
  }
};

export const Login = () => {
  const classes = useStyles();

  const history = useHistory();

  const { login, loginAsGuest } = useContext(AuthContext);

  const handleLoginWithSecret = (values: FormValues) => {
    login(User.makeBySecret(values.secret));
    history.replace("/");
  };

  const handleLoginAsGuest = () => {
    loginAsGuest();
    history.replace("/");
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src="/signatures.png" alt="logo" />
        <Typography component="h1" variant="h5">
          Benvenuto!
        </Typography>
        <Form
          onSubmit={handleLoginWithSecret}
          initialValues={initialValues}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="secret"
                label="Codice Utente*"
                name="secret"
                autoComplete="none"
                autoFocus
              />
              {/* <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Remember me"
        /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Login
              </Button>
              <Grid container justify="center">
                <Grid item>oppure</Grid>
              </Grid>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                className={classes.submit}
                onClick={handleLoginAsGuest}
              >
                Accedi come ospite
              </Button>
            </form>
          )}
        />
      </div>
      <Box mt={8} display="flex" flexDirection="column">
        <Divider />
        <Box mt={4} mr="auto" ml="auto">
          <img
            src="/footer-logo.png"
            alt="nexio logo"
            className="img-fluid img-responsive"
            width="200"
          />
        </Box>
        <Box mt={4} mb={4}>
          <Footer />
        </Box>
      </Box>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
