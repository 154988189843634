import React from "react";
import { Grid, Typography } from "@material-ui/core";

interface Props {
  html: string;
}

export const PreviewContent = ({ html }: Props) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography>Ecco come risulterà la tua firma.</Typography>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 10 }}>
        <div style={{ overflowX: "auto", flex: 1.0 }}>
          <div dangerouslySetInnerHTML={{ __html: html }}></div>
        </div>
      </Grid>
    </>
  );
};
