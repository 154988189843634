import React from "react";
import { TextField } from "@material-ui/core";
import { Template } from "../../types";

type Props = {
  value?: Template.Meta;
  onChange: (url: Template.Meta) => void;
};

export const TemplateUrlPicker = ({ value, onChange }: Props) => {
  const handleChange = (x: string) => {
    onChange(Template.makeMetaFromUri(x));
  };

  return (
    <TextField
      id="template-url"
      label="Url modello"
      value={value?.uri}
      focused={!!value?.uri}
      onChange={(e) => handleChange(e.target.value)}
      fullWidth
    />
  );
};
