interface Version {
  name: string;
  number: number;
}

const localStorageKey = "currentVersion";

const softwareVersion = (): Version => {
  return {
    name: "2.1.0",
    number: 4,
  };
};

const currentVersion = (): Version | null => {
  const versionString = localStorage.getItem(localStorageKey);
  return (versionString && JSON.parse(versionString)) || null;
};

const setupVersion = (): void => {
  const c = currentVersion();
  const v = softwareVersion();

  if (!c || c.number !== softwareVersion().number) {
    localStorage.clear();
    localStorage.setItem(localStorageKey, JSON.stringify(v));
  }
};

export { setupVersion };
